<template>
  <div>
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
      <div class="wcard" v-for="item in lst.da" :key="item.ID">
        <div class="bd" @click="getDetail(item.ID)">
          <van-row :gutter="8">
            <van-col :span="18">
              <p>{{ item.TITLE }} </p>
              <p style="font-size: 14px; color: #999">{{item.DESCRIPTION}}</p>
              <p v-if="item.STEP==91" style="color: red;font-size: 14px;">理由：{{item.REFUSE_DESC}}</p>
            </van-col>
          </van-row>
        </div>
        <div class="ft">
          <van-row>
            <van-col :span="8">{{item.CONF_NAME}}</van-col>
            <van-col :span="8">{{item.CREATED_DT|datetime('YYYY-MM-DD')}}</van-col>
            <van-col :span="8" style="color:#3aa8ec">{{item.STEP_NAME}}</van-col>
          </van-row>
        </div>
      </div>
    </van-list>
    <van-popup v-model="detail.show" position="bottom">
      <van-cell v-for="it in detail.ls" :key="it.ID">
        <span slot="title">{{it.PTEA_NAME}}</span>
        <span slot="default">{{it.STA_TXT}}</span>
        <span slot="label">
          {{it.DESCRIPTION}}
          {{it.CREATED_DT | datetime('YYYY-MM-DD')}}
        </span>
      </van-cell>
    </van-popup>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sea:{
        PAGE_SIZE:10,
        PAGE_INDEX:-1,
        CONF_SN:"",
        FOR:"ALL",
        KEY:"",
      },
      lst:{
        da:[],
        loading:false,
        finish:false
      },
      detail:{
        show:false,
        ls:[]
      }
    }
  },
  created() {
  },
  methods:{
    filter(){
      this.lst.da.length=0;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/WFL/ActApi/GetList",
        data:this.sea,
        completed(its,n){
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    },
    getDetail(id){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/WFL/ActProApi/GetList",
        data: {SID:id},
        completed: function (its) {
          self.detail.show=true;
          self.detail.ls=its.DATA;
        }
      })
    }
  }
}
</script>
<style scoped>
.wcard {margin: 0 12px 12px; overflow: hidden; background-color: #fff; border-radius: 8px; box-shadow: 0 0 4px rgba(0,0,0,.1)}
.wcard .ft {border-top: 1px dashed #ebedf0; padding: 8px 16px; font-size: 12px; min-height: 20px}
.wcard .bd { padding-left: 10px;}
.wcard .bd .lf {text-align: center; color:#ee0a24; padding-top: 10px}
.wcard .bd .lf h2 {font-size: 30px; font-weight: 500; display:inline;}
.wcard .bd .lf span {font-size: 40%; font-weight: normal; margin-left: 2px}
.wcard .bd .lf p {font-size: 14px; line-height: 16px}
</style>